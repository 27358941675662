* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  background: #312e38;
  color: #090c0c;
}

/* https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
body {
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  overflow-x: hidden;
}

html {
  min-height: 100vh;
  height: -webkit-fill-available;
}

a {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
}

a:hover {
  text-decoration: underline;
}

/* Change Autocomplete styles in Chrome*/
/* https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/ */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  /* -webkit-text-fill-color: green; */
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type='date'] {
  /* input date hack/fix for iOS devices */
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

/* google maps centered info box */
.infoBox {
  transform: translateX(-50%) !important;
}
