@font-face {
  font-family: 'MeltedDIN';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/MeltedDIN.ttf');
}

@font-face {
  font-family: 'Futwora-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Futwora-Regular.otf');
}

body.meltshop {
  font-family: 'MeltedDIN';
}
