@font-face {
  font-family: 'Brandon Bold';
  font-style: normal;
  src: url('../assets/fonts/Brandon_bld.otf');
}

@font-face {
  font-family: 'Brandon Black';
  font-style: normal;
  src: url('../assets/fonts/Brandon_blk.otf');
}

@font-face {
  font-family: 'Brandon Light';
  font-style: normal;
  src: url('../assets/fonts/Brandon_light.otf');
}

@font-face {
  font-family: 'Brandon Medium';
  font-style: normal;
  src: url('../assets/fonts/Brandon_med.otf');
}

@font-face {
  font-family: 'Brandon Regular';
  font-style: normal;
  src: url('../assets/fonts/Brandon_reg.otf');
}

@font-face {
  font-family: 'Brandon Thin';
  font-style: normal;
  src: url('../assets/fonts/Brandon_thin.otf');
}

body.lpq {
  font-family: 'Brandon Regular';
}
