@font-face {
  font-family: 'Sofia Pro Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Sofia Pro Bold.otf');
}

@font-face {
  font-family: 'Sofia Pro Medium';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Sofia Pro Medium.otf');
}

@font-face {
  font-family: 'Sofia Pro Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Sofia Pro Regular.otf');
}

@font-face {
  font-family: 'Sofia Pro Semi Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Sofia Pro Semi Bold.otf');
}

@font-face {
  font-family: 'Vesterbro Extrabold';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Vesterbro Extrabold.otf');
}

body.littlebeet {
  font-family: 'Sofia Pro Regular';
}
