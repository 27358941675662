@font-face {
  font-family: 'Gilroy-Extrabold';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Gilroy-Extrabold.otf');
}

@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Gilroy-Bold.otf');
}

@font-face {
  font-family: 'Gilroy-Semibold';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Gilroy-Semibold.otf');
}

@font-face {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Gilroy-Medium.otf');
}

@font-face {
  font-family: 'GT-Pressura-Pro-Mono-Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/GT-Pressura-Pro-Mono-Bold.otf');
}

body.fgc {
  font-family: 'Gilroy-Medium';
}
